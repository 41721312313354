import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { getDemoMode } from "../../../../helpers";

export function Topbar() {
  let demoMode = getDemoMode();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
      viewAdminDisplay: objectPath.get(
        uiService.config,
        "extras.admin.display"
      ),
    };
  }, [uiService]);

  return (
    <>
      {demoMode === "true" ? (
        <div
          className="pull-left pt-7 pr-2 pl-2"
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "white",
            backgroundColor: "#6048fe",
          }}
        >
          This demo is in sandbox mode and you will have limited access to the
          admin panel options. Have a question?{" "}
          <a
            className="text-warning font-weight-bold"
            href="http://www.fundraisingscript.com/contact_us.php"
            target="_blank"
          >
            Contact Us
          </a>
        </div>
      ) : (
        ""
      )}
      <div className="topbar">
        {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}
        {layoutProps.viewUserDisplay && <QuickUserToggler />}
      </div>
    </>
  );
}
