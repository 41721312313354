import { put, call } from "redux-saga/effects";
import {
  SECONDARY_MARKET_API,
  SECONDARY_MARKET_APPROVE_PAYOUT_API,
  SECONDARY_MARKET_TRADE_API,
  SECONDARY_MARKET_TRADE_UPDATE_API,
} from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";
import {
  secondaryMarketStart,
  getSecondaryMarketSettingSuccess,
  getSecondaryMarketSellerTradesSuccess,
  getSellerTradeSuccess,
  secondaryMarketFail,
  updateSecondaryMarketSellerSuccess,
  getSecondaryMarketBuyerTradesSuccess,
} from "../../actions/secondaryMarket/secondaryMarketActions";

import * as campaignsAction from "../../actions/campaigns/campaignsAction";

export function* getSecondaryMarketSettingSaga(action) {
  yield put(secondaryMarketStart());
  try {
    const response = yield axios.get(`${SECONDARY_MARKET_API}`);
    yield put(getSecondaryMarketSettingSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* updateSecondaryMarketSettingSaga(action) {
  try {
    yield axios.patch(
      `${SECONDARY_MARKET_API}/${action.smData.id}`,
      action.smData
    );
    yield call(getSecondaryMarketSettingSaga);
  } catch (error) {
    console.log(error);
  }
}

export function* getSecondaryMarketSellerTradeSaga(action) {
  yield put(secondaryMarketStart());
  try {
    const response = yield axios.get(`${SECONDARY_MARKET_TRADE_API}`, {
      params: {
        ...(action.sellerData.sellingRequestType !== "" && {
          sellingRequestType: action.sellerData.sellingRequestType,
        }),
        ...(action.sellerData.search !== "" && {
          search: action.sellerData.search,
        }),
        ...(action.sellerData.payoutStatus !== "" && {
          payoutStatus: action.sellerData.payoutStatus,
        }),
        ...(action.sellerData.status !== "" && {
          status: action.sellerData.status,
        }),
        ...(action.sellerData.sellingStatus !== "" && {
          sellingStatus: action.sellerData.sellingStatus,
        }),
        ...(action.sellerData.limitNo !== "" && {
          limit: 10 * action.sellerData.limitNo,
        }),
      },
    });
    yield put(getSecondaryMarketSellerTradesSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getSecondaryMarketBuyerTradeSaga(action) {
  yield put(secondaryMarketStart());
  try {
    const response = yield axios.get(`${SECONDARY_MARKET_TRADE_API}`, {
      params: {
        ...(action.buyerData.sellingRequestType !== "" && {
          sellingRequestType: action.buyerData.sellingRequestType,
        }),
        ...(action.buyerData.search !== "" && {
          search: action.buyerData.search,
        }),
        ...(action.buyerData.payoutStatus !== "" && {
          payoutStatus: action.buyerData.payoutStatus,
        }),
        ...(action.buyerData.status !== "" && {
          status: action.buyerData.status,
        }),
        ...(action.buyerData.sellingStatus !== "" && {
          sellingStatus: action.buyerData.sellingStatus,
        }),
        ...(action.buyerData.limitNo !== "" && {
          limit: 10 * action.buyerData.limitNo,
        }),
      },
    });
    yield put(getSecondaryMarketBuyerTradesSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* updateSecondaryMarketSellerTradeSaga(action) {
  try {
    const response = yield axios.patch(
      `${SECONDARY_MARKET_TRADE_UPDATE_API}/${action.sellerData.id}/admin`,
      action.sellerData
    );
    yield put(updateSecondaryMarketSellerSuccess(response.data.data.data));
    yield call(getSecondaryMarketSellerTradeSaga, {
      sellerData: {
        sellingRequestType: "SELL",
        limitNo: 1,
      },
    });
    yield call(getSellerTradeSaga, { sellerId: action.sellerData.id });
  } catch (error) {
    yield put(secondaryMarketFail(error.response.data.message));
  }
}

export function* approveSecondaryMarketSellerTradePayoutRequestSaga(action) {
  try {
    const response = yield axios.patch(
      `${SECONDARY_MARKET_APPROVE_PAYOUT_API}/${action.payload.id}/admin`,
      action.payload
    );
    yield put(updateSecondaryMarketSellerSuccess(response.data.data.data));
    yield call(getSecondaryMarketSellerTradeSaga, {
      sellerData: {
        sellingRequestType: "SELL",
        limitNo: 1,
      },
    });
    yield call(getSellerTradeSaga, { sellerId: action.payload.id });
  } catch (error) {
    yield put(secondaryMarketFail(error.response.data.message));
  }
}

export function* getSecondaryMarketInvestorsSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    const response = yield axios.get(
      SECONDARY_MARKET_TRADE_UPDATE_API +
        "/buy-share/" +
        action.campaignId +
        "/admin"
    );
    yield put(
      campaignsAction.getSecondaryMarketInvestorsSuccess(
        response.data.data.data
      )
    );
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* updateBuyerTradeContractStatusSaga(action) {
  try {
    yield axios.patch(
      `${SECONDARY_MARKET_TRADE_UPDATE_API}/${action.payload.id}/admin`,
      action.payload
    );
    yield call(getSecondaryMarketInvestorsSaga, {
      campaignId: action.payload.campaignId,
    });
  } catch (error) {
    yield put(secondaryMarketFail(error.response.data.message));
  }
}

export function* getSellerTradeSaga(action) {
  yield put(secondaryMarketStart());
  try {
    const response = yield axios.get(
      `${SECONDARY_MARKET_TRADE_UPDATE_API}/${action.sellerId}/admin`
    );
    yield put(getSellerTradeSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}
