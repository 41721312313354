/* eslint-disable no-unused-vars */
import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { DemoOffPage } from "./pages/DemoOffPage";
import Transaction from "./pages/Transaction/Transaction";
import AddTransaction from "./pages/Transaction/AddTransaction";
import Newsletter from "./pages/Newsletter/Newsletter";
import WalletRouter from "./pages/Wallet/WalletRouter";
import { TestingEnvironment } from "./pages/TestingEnvironment/TestingEnvironment";
import { DatabaseManagement } from "./pages/TestingEnvironment/DatabaseManagement";
import { useDispatch } from "react-redux";
import * as actions from "../store/actions/index";
const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserManagement = lazy(() =>
  import("./pages/UserManagement/UserManagementRouter")
);
const Campaign = lazy(() => import("./pages/Campaign/CampaignRouter"));
const APIManagement = lazy(() =>
  import("./pages/APIManagement/APIManagementRouter")
);
const ContentPages = lazy(() =>
  import("./pages/ContentPages/ContentPagesRouter")
);
const Settings = lazy(() => import("./pages/Settings/SettingsRouter"));
const Miscellaneous = lazy(() =>
  import("./pages/Miscellaneous/MiscellaneousRouter")
);
const SpamSetting = lazy(() => import("./pages/SpamSetting/SpamSettingRouter"));
const ManageOptions = lazy(() =>
  import("./pages/ManageOptions/ManageOptionsRouter")
);
const Inbox = lazy(() => import("./pages/Inbox/InboxRouter"));
const Activities = lazy(() => import("./pages/Activities/ActivitiesRouter"));
const ContactList = lazy(() => import("./pages/ContactList/ContactListRouter"));

const NotificationPages = lazy(() =>
  import("./pages/Notification/NotificationRouter")
);

const SecondaryMarket = lazy(() =>
  import("./pages/SecondaryMarket/SecondaryMarketRouter")
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/user-management" component={UserManagement} />
        <Route path="/campaign" component={Campaign} />
        <Route path="/api-management" component={APIManagement} />
        <Route path="/transaction" component={Transaction} />
        <Route path="/add-transaction" component={AddTransaction} />
        <Route path="/wallet" component={WalletRouter} />
        <Route path="/Newsletter" component={Newsletter} />
        <Route path="/content-page" component={ContentPages} />
        <Route path="/settings" component={Settings} />
        <Route path="/miscellaneous" component={Miscellaneous} />
        <Route path="/spam-settings" component={SpamSetting} />
        <Route path="/manage-options" component={ManageOptions} />
        <Route path="/inbox" component={Inbox} />
        <Route path="/activities" component={Activities} />
        <Route path="/maintenance-mode" component={TestingEnvironment} />
        <Route path="/database-management" component={DatabaseManagement} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/contact-list" component={ContactList} />
        <Route
          path="/web-notifications-templates"
          component={NotificationPages}
        />
        <Route path="/secondary-market" component={SecondaryMarket} />

        <Route path="/demo-off" component={DemoOffPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
