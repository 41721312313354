export const NOTIFICATION_TEMPLATES_START = "NOTIFICATION_TEMPLATES_START";
export const GET_NOTIFICATION_TEMPLATES = "GET_NOTIFICATION_TEMPLATES";
export const GET_NOTIFICATION_TEMPLATES_SUCCESS =
  "GET_NOTIFICATION_TEMPLATES_SUCCESS";

export const GET_NOTIFICATION_TEMPLATE = "GET_NOTIFICATION_TEMPLATE";
export const GET_NOTIFICATION_TEMPLATE_SUCCESS =
  "GET_NOTIFICATION_TEMPLATE_SUCCESS";

export const UPDATE_NOTIFICATION_TEMPLATE = "UPDATE_NOTIFICATION_TEMPLATE";
export const UPDATE_NOTIFICATION_TEMPLATE_SUCCESS =
  "UPDATE_NOTIFICATION_TEMPLATE_SUCCESS";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const POST_READ_NOTIFICATIONS = "POST_READ_NOTIFICATIONS";
