import * as actionTypes from "../../actions/savedInvestment/actionTypes";
import { updateObject } from "../../utility/utility";
const initialState = {
  error: [],
  savedInvestments: [],
  successAlert: true,
  isSpinning: false,
};
export const savedInvestmentStart = (state, action) => {
  return updateObject(state, {
    isSpinning: true,
    error: [],
  });
};
export const getSavedInvestments = (state, action) => {
  return updateObject(state, {
    savedInvestment: action.response,
    successAlert: false,
  });
};
export const savedInvestmentFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    successAlert: false,
    isSpinning: false,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVED_INVESTMENT_START:
      return savedInvestmentStart(state, action);
    case actionTypes.GET_SAVED_INVESTMENTS_SUCCESS:
      return getSavedInvestments(state, action);
    case actionTypes.GET_SAVED_INVESTMENTS_FAIL:
      return savedInvestmentFail(state, action);
    default:
      return state;
  }
};
export default reducer;
