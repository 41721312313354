import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

export default function LanguageReplaceText(props) {
  const { languageText, textToReplace, defaultText } = props;
  const { taxonomy } = useSelector((state) => state?.taxonomy);
  return textToReplace ? (
    <span>
      <FormattedMessage
        id={languageText}
        defaultMessage={languageText}
        values={{
          val1: textToReplace?.val1,
          val2: textToReplace?.val2,
          val3: textToReplace?.val3,
          val4: textToReplace?.val4,
          val5: textToReplace?.val5,
          PROJECT_SINGULAR: taxonomy?.projectSingular,
        }}
      />
    </span>
  ) : (
    <div>{defaultText}</div>
  );
}
