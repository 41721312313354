import axios from "../../../config/AxiosConfig";
import { put } from "redux-saga/effects";
import {
  MANGOPAY_TRANSACTION_LIST_BY_USERID,
  MANGOPAY_WALLET_DETAIL_BY_USERID,
} from "../../actions/apiCollections";
import * as actions from "../../actions/mangopay/mangopayActions";

export function* getMangopayWalletTransactionsSaga(action) {
  try {
    const response = yield axios.get(
      `${MANGOPAY_TRANSACTION_LIST_BY_USERID}${action.userId}`,
      {
        params: {
          ...(action.currency !== "" && {
            currency: action.currency,
          }),
          page: 1,
          limit: 10 * action.pageLimit,
        },
      }
    );
    yield put(
      actions.getMangopayWalletTransactionsSuccess(response?.data?.data?.data)
    );
  } catch (error) {
    yield put(actions.mangopayWalletFail(error.response));
  }
}

export function* getMangopayWalletDetailSaga(action) {
  try {
    const response = yield axios.get(
      `${MANGOPAY_WALLET_DETAIL_BY_USERID}${action.userId}`,
      {
        params: {
          ...(action.currency !== "" && {
            currency: action.currency,
          }),
          limit: 10 * action.pageLimit,
        },
      }
    );
    yield put(
      actions.getMangopayWalletDetailSuccess(response?.data?.data?.data)
    );
  } catch (error) {
    yield put(actions.mangopayWalletFail(error.response));
  }
}
