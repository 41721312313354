// let lng = `?lng=${localStorage.getItem("i18nConfig")}`;
export const LOGIN_API = `admins/login?lng=en`;
export const LOGOUT_API = `admins/logout?lng=en`;
export const UPDATE_PASSWORD_API = `/admins/updateMyPassword`;
export const GET_ALL_ADMINS_API = `admins`;
export const GET_ALL_ADMIN_ROLES_API = `admin/roles`;
export const CREATE_NEW_ADMIN_API = `admins`;
export const GET_ADMIN_BY_ID_API = `admins/`;
export const UPDATE_ADMIN_BY_ID_API = `admins/`;
export const DELETE_ADMIN_BY_ID_API = `admins/`;
export const GET_ALL_CATEGORY_API = `admin/categories`;
export const CREATE_CATEGORY_API = `admin/categories`;
export const GET_CATEGORY_BY_ID_API = `admin/categories/`;
export const UPDATE_CATEGORY_BY_ID_API = `admin/categories/`;
export const DELETE_CATEGORY_BY_ID_API = `admin/categories/`;
export const MANAGE_OPTIONS_COMPANY_INDUSTRY_GET_API = `admin/manage-options/company-industry/`;
export const MANAGE_OPTIONS_COMPANY_INDUSTRY_CREATE_API = `admin/manage-options/company-industry`;
export const MANAGE_OPTIONS_COMPANY_INDUSTRY_DELETE_API = `admin/manage-options/company-industry/`;
export const MANAGE_OPTIONS_COMPANY_INDUSTRY_UPDATE_API = `admin/manage-options/company-industry/`;
export const MANAGE_OPTIONS_INVESTING_SOURCE_LIST_API = `admin/manage-options/investing-source/`;
export const MANAGE_OPTIONS_INVESTING_TYPE_API = `admin/manage-options/investing-type/`;
export const MANAGE_OPTIONS_DOCUMENT_TYPE_API = `admin/manage-options/document-type/`;
export const MANAGE_OPTIONS_ACCOUNT_TYPE_API = `admin/manage-options/account-type/`;
export const MANAGE_OPTIONS_TEAM_MEMBER_TYPE_API = `admin/manage-options/team-member-type/`;
export const MANAGE_OPTIONS_INVESTOR_TYPE_API = `admin/manage-options/investor-type/`;
export const MANAGE_OPTIONS_CAMPAIGN_STAGE_API = `admin/manage-options/campaign-stage/`;
export const MANAGE_OPTIONS_INVESTING_ROUND_API = `admin/manage-options/investing-round/`;
export const MANAGE_OPTIONS_DEAL_TYPE = "admin/deal-types";
export const GET_LANGUAGES = `admin/languages`;
export const SET_DEFAULT_LANGUAGE_API = `admin/languages/setDefault/`;
export const FRONT_END_USER = `users/`;
export const BULK_FRONT_END_USER = `users/bulk-updates/`;
export const CURRENCY_API = `admin/currencies`;
export const ACTIVE_CURRENCY_LIST_API = `admin/currencies/front`;
export const COUNTRIES_API = `admin/manage-options/countries/`;
export const SITE_SETTINGS_API = `admin/site-setting`;
export const SLIDERS_API = `admin/sliders`;
export const FAQS_API = `admin/content-page/faqs`;
export const PAGES_API = `admin/content-page/pages`;
export const HELP_CATEGORY = `admin/content-page/help-center-categories`;
export const HELP_CENTER_API = `admin/content-page/help-centers`;
export const CAMPAIGN_SETTING_API = `admin/campaign-setting/`;
export const INVESTOR_TAX_RELIEF = `admin/investor-tax-reliefs`;
export const CURRENT_ADMIN = `admins/me`;
export const MENU_API = `admin/menus/`;
export const PAYMENT_GATEWAY_API = `admin/payment-gateway/`;
export const RECAPTCHA_API = "admin/recaptcha/";
export const SOCIAL_SIGNUP_API = `admin/social-signup/`;
export const LANGUAGE_SELECTOR_API = `admin/languages?fields=name,folder,flagImage,status&sort=createdAt`;
export const REVENUE_API = `admin/revenue-setting/`;
export const ROLE_API = `admin/roles/`;
export const META_API = `admin/meta-setting/`;
export const TAXONOMY_API = `admin/taxonomy-setting/front`;
export const SPAM_SETTING_API = `admin/spam-setting/`;
export const SPAMMERS_API = `admin/spammers/`;
export const BULK_SPAMMERS_DELETE_API = `admin/spammers/bulk-delete`;
export const BULK_SPAMMERS_UPDATE_API = `admin/spammers/bulk-updates`;
export const ADMIN_BULK_UPDATE = `admins/bulk-updates/`;
export const ADMIN_BULK_DELETE = `admins/bulk-delete/`;
export const CATEGORY_BULK_UPDATE = `admin/categories/bulk-updates/`;
export const CATEGORY_BULK_DELETE = `admin/categories/bulk-delete/`;
export const CURRENCY_BULK_UPDATE = `admin/currencies/bulk-updates/`;
export const CURRENCY_BULK_DELETE = `admin/currencies/bulk-delete/`;
export const ROLE_BULK_UPDATE = `admin/roles/bulk-updates/`;
export const ROLE_BULK_DELETE = `admin/roles/bulk-delete/`;
export const SLIDER_BULK_UPDATE = `admin/sliders/bulk-updates/`;
export const SLIDER_BULK_DELETE = `admin/sliders/bulk-delete/`;
export const DASHBOARD_API = `admin/dashboard/`;
export const CAMPAIGN_LIST_API = `equities/`;
export const TRANSACTIONS_API = `transactions/`;
export const INVESTMENT_API = `process-investment/`;
export const DOCUMENTS_API = `admin/admin-campaign-detail/`;
export const INVESTORS_API = `investor-profiles/`;
export const PERMISSIONS_API = `admin/roles/get-permission/`;
export const ACTIVITY_API = `admin/admin-activity/`;
export const EMAIL_SETTINGS_API = `admin/email-templates/`;
export const UPDATE_MASTER_EMAIL_API = `admin/email-templates/update-master-email`;
export const SEND_TEST_EMAIL_API = `admin/site-setting/send-test-email`;
export const CAMPAIGN_ACTIVITY_API = `equity/campaign-activity/`;
export const CRON_JOB_API = `admin/cron/`;
export const REPAYMENT_API = `admin/repayment/`;
export const DISTRIBUTION_API = `admin/distribution/`;
export const STORIES_API = `admin/success-stories/`;
export const LOGIN_USER = `users/login-as-user/`;
export const USERS_CONTACT_LIST_API = `admin/contact-us`;
export const LOGIN_ADMIN_FRONTEND = `admins/login-as-user`;

export const STRIPE_ACH_PAYMENT_TOKEN_API = `equity/ach-payment/create-admin-payment-link-token`;
export const STRIPE_ACH_PAYMENT_CONFIRMATION_ID_API = `equity/ach-payment/process-payout`;

export const SUBSCRIBERS_API = `admin/subscribers/`;
export const CAMPAIGNS_REVENUES_TOTAL_API = `admin/dashboard/get-revenue-chart-data`;
export const CAMPAIGNS_INVESTMENTS_TOTAL_API = `admin/dashboard/get-investment-chart-data`;
export const CAMPAIGNS_REPAYMENT_TOTAL_API = `admin/dashboard/get-repayment-chart-data`;
export const USER_TYPE_API = `admin/usertypes`;
export const NEWSLETTER_LIST_API = `admin/other-setting/`;
export const FOOTER_FAQ_API = `admin/front-faqs`;
export const ACTIVE_CAMPAIGN_LIST_API = `equities/get-active-campaign-list/admin`;
export const INVESTOR_LIST_API = `users/active-investor-users/admin`;
export const INVESTOR_CONTRACT_API = `/contracts/investors-contract`;

export const CLEAR_CACHE_API = `admin/site-setting/clear-cache`;
export const CLEAN_DATABASE_API = `admin/clean-collection/clean-database`;

export const WALLET_TRANSACTION_LIST_API = `wallet/get-all-wallet-transactions/admin`;
export const WALLET_STATUS_UPDATE_API = `wallet/update-wallet-transaction-status`;

export const ALL_SAVED_INVESTMENT_LIST = `save-investment/get-all-saved-investment/admin`;
export const SAVED_INVESTMENT_LIST = `save-investment`;
export const DELETE_SAVED_INVESTMENT = `save-investment/delete-saved-investment/`;
export const USER_INVESTMENTS_LIST = `users/get-user-investments`;

export const MANGOPAY_TRANSACTION_LIST_BY_USERID = `equity/mangopay-payment/wallet-transaction-list/`;
export const MANGOPAY_WALLET_DETAIL_BY_USERID = `equity/mangopay-payment/wallet-detail/`;

// Share Certicate API
export const CAMPAIGN_SHARE_CERTIFICATE_API = `equity/share-certificate/`;

export const TRANSACTIONS_EXPORT_API = `transactions/export/`;

// Notification Templates
export const NOTIFICATION_TEMPLATES_API = `admin/notification-templates/`;
export const NOTIFICATIONS_API = `notifications/admin`;

// Secondary Market API
export const SECONDARY_MARKET_API = `admin/secondary-market-setting`;
export const SECONDARY_MARKET_TRADE_API = `secondary-market/admin`;
export const SECONDARY_MARKET_TRADE_UPDATE_API = `secondary-market`;
export const SECONDARY_MARKET_APPROVE_PAYOUT_API = `secondary-market/payout`;
export const DATABASE_BACKUP_API = `admin/database/list-backup`;
export const DOWNLOAD_DATABASE_BACKUP_API = `admin/database/download-backup`;

// Account Verification
export const ACCOUNT_VERIFICATION_USERS = `account-verification/admin`;
export const EDIT_ACCOUNT_VERIFICATION_USER = `account-verification`;
