import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
  editAccountVerificationUserFail,
  editAccountVerificationUserStart,
  editAccountVerificationUserSuccess,
  getAccountVerificationUserFail,
  getAccountVerificationUsersFail,
  getAccountVerificationUsersStart,
  getAccountVerificationUsersSuccess,
  getAccountVerificationUserStart,
  getAccountVerificationUserSuccess,
} from "../actions/index";
import { ACCOUNT_VERIFICATION_USERS } from "../actions/apiCollections";

export function* getAccountVerificationUsersSaga(action) {
  yield put(getAccountVerificationUsersStart());
  try {
    const response = yield axios.get(ACCOUNT_VERIFICATION_USERS, {
      params: {
        ...(action.search !== "" && {
          search: action.search,
        }),

        limit: 10 * action.pageLimit,
      },
    });

    yield put(getAccountVerificationUsersSuccess(response.data.data.data));
  } catch (error) {
    yield put(getAccountVerificationUsersFail(error.response.data));
  }
}

export function* getAccountVerificationUserSaga(action) {
  yield put(getAccountVerificationUserStart());
  try {
    const response = yield axios.get(
      `${ACCOUNT_VERIFICATION_USERS}/${action.id}`
    );

    yield put(getAccountVerificationUserSuccess(response.data.data.data));
  } catch (error) {
    yield put(getAccountVerificationUserFail(error.response.data));
  }
}

export function* editAccountVerificationUser(action) {
  yield put(editAccountVerificationUserStart());
  try {
    const response = yield axios.patch(
      `${ACCOUNT_VERIFICATION_USERS}/${action.id}`,
      action.formData
    ); // API call

    yield put(editAccountVerificationUserSuccess(response.data.data.data));
  } catch (error) {
    yield put(editAccountVerificationUserFail(error.response.data));
  }
}
