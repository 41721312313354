import React, { useLayoutEffect, useEffect, useRef, useState } from "react";
import {
  Form,
  Modal,
  OverlayTrigger,
  Table,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import SVG from "react-inlinesvg";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import SearchTransactions from "../SearchWalletTransactions";

import {
  capitalize,
  getBaseImageURL,
  getWalletCSSClassName,
  getWalletStatusCodes,
  decryptedData,
} from "../../../../helpers";
import { formatCurrency } from "../../../../helpers/numberFormat";
import * as actions from "../../../../store/actions/index";

function WalletTransactions({ history }) {
  const dispatch = useDispatch();
  const globalImagePath = useRef(getBaseImageURL());
  const intl = useIntl();
  const methods = useForm({ mode: "onChange" });
  const {
    register: modalRegister,
    formState: { errors: modalErrors },
    reset: modalReset,
    handleSubmit: modalHandleSubmit,
    setValue: modalSetValue,
  } = useForm({ mode: "onChange" });

  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState(2);
  const [searchData, setSearchData] = useState("");
  const [selectedTransactionInfo, setSelectedTransactionInfo] = useState();
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState();
  const isEncryption = useRef(
    process.env.REACT_APP_DATABASE_ENCRYPTION === "true"
  );

  useEffect(() => {
    dispatch(actions.getWalletTransactions(1, searchData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { loadingTransactions, isSpinning, walletTransactions } = useSelector(
    (state) => state.wallet
  );
  const { generalSettings } = useSelector((state) => state.settings);

  useLayoutEffect(() => {
    if (generalSettings?.walletModule === "no") {
      history.push(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSettings?.walletModule]);

  const clearSearchData = () => {
    setSearchData("");
    setShowMore(2);
    dispatch(actions.getWalletTransactions(1, ""));
  };

  const handleClose = () => {
    modalReset();
    setSelectedTransactionInfo();
    setSelectedTransactionStatus();
    setShow(false);
  };

  const loadMore = () => {
    setShowMore(showMore + 1);
    dispatch(actions.getWalletTransactions(showMore, searchData));
  };

  const onSubmit = (data) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowMore(1);
    setSearchData(data);
    dispatch(actions.getWalletTransactions(1, data));
  };

  const onUpdateTransactionSubmit = (data) => {
    data.status = selectedTransactionStatus;
    dispatch(
      actions.updateWalletTransaction(
        selectedTransactionInfo._id,
        data,
        showMore,
        searchData
      )
    );
    handleClose();
  };

  const walletTransactionList =
    walletTransactions?.docs?.length > 0 ? (
      walletTransactions?.docs?.map((transaction, key) => (
        <tr key={key}>
          <td className="text-nowrap">
            {isEncryption.current
              ? decryptedData(transaction?.user?.fullName?.split(" ")[0]) +
                " " +
                decryptedData(transaction?.user?.fullName?.split(" ")[1])
              : transaction?.user?.fullName}
          </td>
          <td className="text-nowrap">
            {moment(transaction?.createdAt).format(
              generalSettings.dateFormat
            ) ?? "-"}
          </td>
          <td>
            {isEncryption.current
              ? decryptedData(transaction?.transactionNumber)
              : transaction?.transactionNumber}
          </td>
          <td
            className={
              transaction?.walletType === "CREDIT"
                ? "text-success"
                : "text-danger"
            }
            style={{
              maxWidth: "15rem",
              wordBreak: "break-all",
            }}
          >
            {formatCurrency(
              transaction?.amount ?? 0,
              generalSettings?.currencySymbolSide,
              transaction?.currencyId?.symbol,
              transaction?.currencyId?.code,
              generalSettings?.decimalPoints
            )}
          </td>
          <OverlayTrigger
            key={key}
            placement={"right"}
            overlay={
              <Tooltip>
                <span className="row p-1">
                  Transaction Fees:{" "}
                  <b>
                    {" "}
                    {transaction?.feesDetails?.transactionFees.toFixed(2) ?? 0}
                  </b>
                </span>
                <span className="row p-1">
                  Gateway Fee Percentage:
                  <b> {transaction?.feesDetails?.feesPercentage.toFixed(2)}</b>
                </span>
                <span className="row p-1">
                  Gateway Flat Fee:
                  <b> {transaction?.feesDetails?.flatFees.toFixed(2)}</b>
                </span>
              </Tooltip>
            }
          >
            <td className="text-nowrap">
              {formatCurrency(
                transaction?.feesDetails?.transactionFees ?? 0,
                generalSettings?.currencySymbolSide,
                transaction?.currencyId?.symbol,
                transaction?.currencyId?.code,
                generalSettings?.decimalPoints
              )}
            </td>
          </OverlayTrigger>
          <td>{transaction?.gatewayId?.title ?? "-"}</td>
          <td>{transaction?.walletType}</td>
          <td>
            <span
              className={`label label-lg ${getWalletCSSClassName(
                transaction?.status
              )} label-inline`}
            >
              {getWalletStatusCodes(transaction?.status)}
            </span>
          </td>
          <td>
            <>
              <OverlayTrigger
                placement="bottom"
                popperConfig={{
                  modifiers: {
                    preventOverflow: {
                      enabled: false,
                    },
                  },
                }}
                overlay={<Tooltip>Edit</Tooltip>}
              >
                <button
                  onClick={() => {
                    setSelectedTransactionInfo(transaction);
                    setSelectedTransactionStatus(transaction?.status);
                    modalSetValue("rejectReason", transaction?.rejectReason);
                    setShow(true);
                  }}
                  className={`btn btn-icon btn-light btn-sm ml-1 mr-2 ${
                    transaction?.status === 0 ? "symbol" : ""
                  }`}
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      title=" "
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Visible.svg"
                      )}
                    />
                  </span>
                  <i className="symbol-badge bg-danger" />
                </button>
              </OverlayTrigger>
            </>
          </td>
        </tr>
      ))
    ) : (
      <tr className="text-center">
        <td colSpan={10}>
          <p>{loadingTransactions ? "Loading..." : "No Record Found!"}</p>
        </td>
      </tr>
    );

  return (
    <>
      <FormProvider {...methods}>
        <Form
          name="SearchTransactions"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <SearchTransactions
            showTransactionType={true}
            clearSearchData={() => clearSearchData()}
          />
        </Form>
      </FormProvider>
      <Card>
        <CardHeader title="Wallet Transaction List">
          <div className="header-buttons"></div>
        </CardHeader>
        <CardBody>
          <Table responsive className="table-head-custom table-vertical-center">
            <thead>
              <tr>
                <th>User</th>
                <th>Date</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Fees</th>
                <th>Gateway</th>
                <th>Type</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{walletTransactionList}</tbody>
          </Table>
          {walletTransactions?.displayLoadMore ? (
            <div className="mt-5 text-center">
              <button
                type="button"
                className="btn btn-primary custom-btn mt-5 text-center"
                onClick={() => loadMore()}
                disabled={isSpinning}
              >
                <span
                  className={
                    isSpinning
                      ? ` text-center spinner spinner-center p-5 m-5`
                      : ""
                  }
                >
                  {isSpinning ? "" : "Load More"}
                </span>
              </button>
            </div>
          ) : null}
        </CardBody>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50 mr-5">
                <div
                  className="symbol-label"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      selectedTransactionInfo?.user?.photo
                        ? `${globalImagePath.current}${selectedTransactionInfo?.user?.photo}`
                        : "/media/users/default.jpg"
                    )})`,
                  }}
                />
              </div>
              <div>
                <a
                  href="#"
                  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                >
                  {isEncryption.current
                    ? decryptedData(
                        selectedTransactionInfo?.user?.fullName?.split(" ")[0]
                      ) +
                      " " +
                      decryptedData(
                        selectedTransactionInfo?.user?.fullName?.split(" ")[1]
                      )
                    : selectedTransactionInfo?.user?.fullName}
                </a>
                <div className="navi mt-2">
                  <a href={undefined} className="navi-item">
                    <span className="navi-link p-0 pb-2">
                      <span className="navi-icon mr-1">
                        <span className="svg-icon-lg svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-notification.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                      <span className="navi-text text-muted text-hover-primary">
                        {isEncryption.current
                          ? decryptedData(selectedTransactionInfo?.user?.email)
                          : selectedTransactionInfo?.user?.email}
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="offcanvas-content pr-5 mr-n5">
            {selectedTransactionInfo?.gatewayId?.title === "Offline" ? (
              <>
                <div className="separator separator-dashed my-7" />
                <div>
                  <h5 className="mb-5">Acknowledgement Document</h5>
                  <ul className="p-0 m-0">
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Document</span>
                      <strong className="ml-4">
                        <a
                          href={`${globalImagePath.current}${selectedTransactionInfo?.acknowledgeDocument}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          Download
                        </a>
                      </strong>
                    </li>
                  </ul>
                </div>
              </>
            ) : null}

            {selectedTransactionInfo?.walletType === "DEBIT" &&
            selectedTransactionInfo?.bankName ? (
              <>
                <div className="separator my-7" />
                <h5 className="mb-5">Bank Details</h5>
                <ul className="p-0 m-0">
                  {selectedTransactionInfo?.bankName ? (
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Bank Name</span>
                      <strong className="ml-4">
                        {decryptedData(selectedTransactionInfo?.bankName)}
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.accountType ? (
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Account Type</span>
                      <strong className="ml-4">
                        {selectedTransactionInfo?.accountType}
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.accountNumber ? (
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Account Number</span>
                      <strong className="ml-4">
                        {decryptedData(selectedTransactionInfo?.accountNumber)}
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.routingNumber ? (
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Routing Number</span>
                      <strong className="ml-4">
                        {decryptedData(selectedTransactionInfo?.routingNumber)}
                      </strong>
                    </li>
                  ) : null}
                </ul>
              </>
            ) : null}
            <div className="separator my-7" />

            <div>
              <h5 className="mb-5">Payment Details</h5>

              <ul className="p-0 m-0">
                {selectedTransactionInfo?.gatewayId?.title ? (
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>Payment Gateway</span>
                    <strong className="ml-4">
                      {capitalize(selectedTransactionInfo?.gatewayId?.title)}
                    </strong>
                  </li>
                ) : null}
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Wallet ID</span>
                  <strong className="ml-4">
                    {isEncryption.current
                      ? decryptedData(selectedTransactionInfo?.walletId) ?? "-"
                      : (selectedTransactionInfo?.walletId) ?? "-"}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Amount</span>
                  <strong className="ml-4">
                    {formatCurrency(
                      selectedTransactionInfo?.amount,
                      generalSettings.currencySymbolSide,
                      selectedTransactionInfo?.currencyId?.symbol,
                      selectedTransactionInfo?.currencyId?.code,
                      generalSettings.decimalPoints
                    )}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Transaction Fees:</span>
                  <strong className="ml-4">
                    {formatCurrency(
                      selectedTransactionInfo?.feesDetails?.transactionFees ??
                        0,
                      generalSettings?.currencySymbolSide,
                      selectedTransactionInfo?.currencyId?.symbol,
                      selectedTransactionInfo?.currencyId?.code,
                      generalSettings?.decimalPoints
                    )}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Status</span>
                  <span
                    className={`label label-lg ${getWalletCSSClassName(
                      selectedTransactionInfo?.status
                    )} label-inline`}
                  >
                    {getWalletStatusCodes(selectedTransactionInfo?.status)}
                  </span>
                </li>

                <Form
                  name="Transactions"
                  onSubmit={modalHandleSubmit(onUpdateTransactionSubmit)}
                >
                  <li className="d-flex justify-content-between pt-3">
                    <span>Action</span>
                    <ToggleButtonGroup type="radio" name="status">
                      <ToggleButton
                        variant="secondary"
                        size="sm"
                        className={
                          selectedTransactionStatus === 2 ? "active" : ""
                        }
                        onClick={() =>
                          selectedTransactionInfo?.transactionType !== 0 &&
                          selectedTransactionInfo?.transactionType !== 5
                            ? setSelectedTransactionStatus(2)
                            : null
                        }
                        disabled={
                          selectedTransactionInfo?.transactionType === 0 ||
                          selectedTransactionInfo?.transactionType === 5 ||
                          selectedTransactionInfo?.status === 1
                        }
                      >
                        Approve
                      </ToggleButton>
                      <ToggleButton
                        variant="secondary"
                        className={
                          selectedTransactionStatus === 3 ? "active" : ""
                        }
                        size="sm"
                        onClick={() =>
                          selectedTransactionInfo?.transactionType !== 0 &&
                          selectedTransactionInfo?.transactionType !== 5
                            ? setSelectedTransactionStatus(3)
                            : null
                        }
                        disabled={
                          selectedTransactionInfo?.transactionType === 0 ||
                          selectedTransactionInfo?.transactionType === 5 ||
                          selectedTransactionInfo?.status === 1
                        }
                      >
                        Reject
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </li>

                  {selectedTransactionStatus === 3 ? (
                    <>
                      <Form.Label className="mt-2">
                        Reason to Reject
                        <span className="text-important ml-2 mr-2">*</span>
                      </Form.Label>
                      <Form.Control
                        name="rejectReason"
                        as="textarea"
                        rows="3"
                        placeholder={"Reason to Reject"}
                        className={classNames("form-control", {
                          "is-invalid": modalErrors.rejectReason,
                        })}
                        {...modalRegister("rejectReason", {
                          required: intl.formatMessage({
                            id: "COMMON.FIELD.REQ",
                          }),
                        })}
                      />
                      <ErrorMessage
                        errors={modalErrors}
                        name="rejectReason"
                        render={({ message }) => (
                          <div className="invalid-feedback">{message}</div>
                        )}
                      />
                    </>
                  ) : null}
                  <div>
                    <hr />
                    <button
                      type="submit"
                      className="btn btn-primary ml-2"
                      disabled={
                        selectedTransactionStatus === 0 ||
                        selectedTransactionStatus ===
                          selectedTransactionInfo?.status
                      }
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary ml-2"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default WalletTransactions;
