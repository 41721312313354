export const MANGOPAY_WALLET_START = "MANGOPAY_WALLET_START";
export const MANGOPAY_WALLET_FAIL = "MANGOPAY_WALLET_FAIL";
export const GET_MANGOPAY_WALLET_TRANSACTIONS =
  "GET_MANGOPAY_WALLET_TRANSACTIONS";
export const GET_MANGOPAY_WALLET_TRANSACTIONS_SUCCESS =
  "GET_MANGOPAY_WALLET_TRANSACTIONS_SUCCESS";

export const GET_MANGOPAY_WALLET_DETAIL = "GET_MANGOPAY_WALLET_DETAIL";
export const GET_MANGOPAY_WALLET_DETAIL_SUCCESS =
  "GET_MANGOPAY_WALLET_DETAIL_SUCCESS";
