import * as actionTypes from "./actionTypes";

export const secondaryMarketStart = () => {
  return {
    type: actionTypes.SECONDARY_MARKET_START,
  };
};

export const secondaryMarketFail = (error) => {
  return {
    type: actionTypes.SECONDARY_MARKET_FAIL,
    error,
  };
};

export const getSecondaryMarketSetting = (response) => {
  return {
    type: actionTypes.GET_SECONDARY_MARKET_SETTING,
    response,
  };
};

export const getSecondaryMarketSettingSuccess = (response) => {
  return {
    type: actionTypes.GET_SECONDARY_MARKET_SETTING_SUCCESS,
    response,
  };
};

export const updateSecondaryMarketSetting = (smData) => {
  return {
    type: actionTypes.UPDATE_SECONDARY_MARKET_SETTING,
    smData,
  };
};

export const getSecondaryMarketSellerTrades = (sellerData) => {
  return {
    type: actionTypes.GET_SECONDARY_MARKET_SELLER_TRADE,
    sellerData,
  };
};

export const getSecondaryMarketSellerTradesSuccess = (response) => {
  return {
    type: actionTypes.GET_SECONDARY_MARKET_SELLER_TRADE_SUCCESS,
    response,
  };
};

export const getSecondaryMarketBuyerTrades = (buyerData) => {
  return {
    type: actionTypes.GET_SECONDARY_MARKET_BUYER_TRADE,
    buyerData,
  };
};

export const getSecondaryMarketBuyerTradesSuccess = (response) => {
  return {
    type: actionTypes.GET_SECONDARY_MARKET_BUYER_TRADE_SUCCESS,
    response,
  };
};

export const getSellerTrade = (sellerId) => {
  return {
    type: actionTypes.GET_SELLER_TRADE,
    sellerId,
  };
};

export const getSellerTradeSuccess = (response) => {
  return {
    type: actionTypes.GET_SELLER_TRADE_SUCCESS,
    response,
  };
};

export const getSellerCalculateFees = (response) => {
  return {
    type: actionTypes.GET_SELLER_CALCULATE_FEES,
    response,
  };
};

export const updateSecondaryMarketSellerTrades = (sellerData) => {
  return {
    type: actionTypes.UPDATE_SECONDARY_MARKET_SELLER_TRADE,
    sellerData,
  };
};

export const updateSecondaryMarketSellerSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_SECONDARY_MARKET_SELLER_TRADE_SUCCESS,
    response,
  };
};

export const approveSecondaryMarketSellerTradesPayoutRequest = (payload) => {
  return {
    type: actionTypes.APPROVE_SECONDARY_MARKET_SELLER_TRADE_PAYOUT_REQUEST,
    payload,
  };
};

export const updateBuyerContractDocumentStatus = (payload) => {
  return {
    type: actionTypes.UPDATE_SECONDARY_MARKET_BUYER_CONTRACT_DOCUMENT_REQUEST,
    payload,
  };
};
