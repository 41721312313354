import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  accountVerificationUsers: [],
  accountVerificationUser: null,
  loading: false,
  error: null,
};

/* get list of account verification users */
export const getAccountVerificationUsersStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
    accountVerificationUsers: [],
  });
};

export const getAccountVerificationUsers = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    accountVerificationUsers: action.response,
  });
};

export const getAccountVerificationUsersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

/* get single account verification user */

export const getAccountVerificationUserStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
    accountVerificationUser: null,
  });
};

export const getAccountVerificationUser = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    accountVerificationUser: action.response,
  });
};

export const getAccountVerificationUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

/* edit account verification user */

export const editAccountVerificationUserStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

export const editAccountVerificationUser = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    accountVerificationUser: action.response,
  });
};

export const editAccountVerificationUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const accountVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_VERIFICATION_USERS_START:
      return getAccountVerificationUsersStart(state);

    case actionTypes.GET_ACCOUNT_VERIFICATION_USERS_SUCCESS:
      return getAccountVerificationUsers(state, action);

    case actionTypes.GET_ACCOUNT_VERIFICATION_USERS_FAIL:
      return getAccountVerificationUsersFail(state, action);

    case actionTypes.GET_ACCOUNT_VERIFICATION_USER_START:
      return getAccountVerificationUserStart(state);

    case actionTypes.GET_ACCOUNT_VERIFICATION_USER_SUCCESS:
      return getAccountVerificationUser(state, action);

    case actionTypes.GET_ACCOUNT_VERIFICATION_USERS_FAIL:
      return getAccountVerificationUserFail(state, action);

    case actionTypes.EDIT_ACCOUNT_VERIFICATION_USER_START:
      return editAccountVerificationUserStart(state);

    case actionTypes.EDIT_ACCOUNT_VERIFICATION_USER_SUCCESS:
      return editAccountVerificationUser(state, action);

    case actionTypes.EDIT_ACCOUNT_VERIFICATION_USER_FAIL:
      return editAccountVerificationUserFail(state, action);

    default:
      return state;
  }
};

export default accountVerificationReducer;
