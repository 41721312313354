/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import { getDashboardCampaignTotalInvestment } from "../../../../store/actions/dashboard/dashboardActions";
import { formatCurrency } from "../../../../helpers/numberFormat";

export function StatsWidget11({ className }) {
  const dispatch = useDispatch();
  const [monthsInvestments, setMonthsInvestments] = useState([]);
  const [monthlyInvestmentsTotal, setMonthlyInvestmentsTotal] = useState([]);
  const [currentInvestmentsCurrency, setCurrentInvestmentsCurrency] = useState("");

  const { totalCampaignInvestments, dashboardSelectedCurrency } = useSelector((state) => state.dashboard);
  const { generalSettings } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(getDashboardCampaignTotalInvestment(dashboardSelectedCurrency));
  }, [dispatch, dashboardSelectedCurrency]);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(uiService.config, "js.colors.gray.gray500"),
      colorsGrayGray200: objectPath.get(uiService.config, "js.colors.gray.gray200"),
      colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
      colorsThemeBaseSuccess: objectPath.get(uiService.config, "js.colors.theme.base.success"),
      colorsThemeLightSuccess: objectPath.get(uiService.config, "js.colors.theme.light.success"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
      colorsThemeLightBasePrimary: objectPath.get(uiService.config, "js.colors.theme.light.primary"),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    if (totalCampaignInvestments) {
      setCurrentInvestmentsCurrency(totalCampaignInvestments?.currentCurrencyData?.symbol);

      const monthsCampaignInvestments = totalCampaignInvestments?.investmentData?.map(function (item) {
        return item["_id"];
      });
      setMonthsInvestments(monthsCampaignInvestments);

      const campaignInvestmentsTotal = totalCampaignInvestments?.investmentData?.map(function (item) {
        return item["totalAmount"];
      });
      setMonthlyInvestmentsTotal(campaignInvestmentsTotal);
    }
  }, [totalCampaignInvestments]);

  useEffect(() => {
    if (layoutProps && monthsInvestments && monthlyInvestmentsTotal && currentInvestmentsCurrency) {
      const element = document.getElementById("kt_stats_widget_7_chart");

      if (!element) {
        return;
      }

      const options = getChartOption(layoutProps, monthsInvestments, monthlyInvestmentsTotal, currentInvestmentsCurrency);
      const chart = new ApexCharts(element, options);
      chart.render();
      return function cleanUp() {
        chart.destroy();
      };
    }
  }, [layoutProps, monthsInvestments, monthlyInvestmentsTotal, currentInvestmentsCurrency]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <div className="d-flex flex-column mr-2">
            <a href="#" className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">
              Investments
            </a>
            <span className="text-muted font-weight-bold mt-1">
              Total Investments:{" "}
              {formatCurrency(
                totalCampaignInvestments?.totalInvestment ?? 0,
                generalSettings?.currencySymbolSide,
                totalCampaignInvestments?.currentCurrencyData?.symbol,
                totalCampaignInvestments?.currentCurrencyData?.code,
                generalSettings?.decimalPoints
              )}
            </span>
          </div>
        </div>
        <div id="kt_stats_widget_7_chart" className="card-rounded-bottom" style={{ height: "150px" }}></div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps, monthsInvestments, monthlyInvestmentsTotal, currentInvestmentsCurrency) {
  const options = {
    series: [
      {
        name: "Total",
        data: monthlyInvestmentsTotal,
      },
    ],
    chart: {
      type: "area",
      height: 150,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBasePrimary],
    },
    xaxis: {
      categories: monthsInvestments,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function (val) {
          return `${currentInvestmentsCurrency}` + val.toLocaleString();
        },
      },
    },
    colors: [layoutProps.colorsThemeLightBasePrimary],
    markers: {
      colors: [layoutProps.colorsThemeLightBasePrimary],
      strokeColor: [layoutProps.colorsThemeBasePrimary],
      strokeWidth: 3,
    },
  };
  return options;
}
