import React from "react";
import { Dashboard } from "../../_metronic/_partials";
import { useDispatch } from "react-redux";
import { authCheckState } from "../../store/actions/index";
export function DashboardPage() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch]);

  return <Dashboard />;
}
