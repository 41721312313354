/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/users/login-1.scss";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/index";
import { getBaseImageURL } from "../../../../helpers";

export function AuthPage() {
  const dispatch = useDispatch();
  const globalImagePath = useRef(getBaseImageURL());
  const [logo, setLogo] = useState();

  useEffect(() => {
    dispatch(actions.getFrontGeneralSettings());
  }, [dispatch]);

  const { generalSettings } = useSelector((state) => state.settings);

  useEffect(() => {
    if (generalSettings?.siteLogo) {
      setLogo(generalSettings?.siteLogo);
    }
  }, [generalSettings?.siteLogo]);

  document.title = `Welcome to ${generalSettings?.siteName}`;

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-9.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between ">
              {/* start:: Aside header */}
              <Link
                to="/"
                className="flex-column-auto mt-5"
                onMouseEnter={() => {
                  setLogo(generalSettings?.darkLogo);
                }}
                onMouseLeave={() => {
                  setLogo(generalSettings?.siteLogo);
                }}
              >
                {logo ? (
                  <img
                    alt="Logo"
                    className="max-h-70px"
                    src={`${globalImagePath.current}${logo}`}
                  />
                ) : null}
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-black">
                  Welcome to <br /> {generalSettings?.siteName}
                </h3>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-black">
                  &copy; {new Date().getFullYear()} {generalSettings?.siteName}
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}

            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {new Date().getFullYear()} {generalSettings?.siteName}
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
