import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import {
  exportCSVStart,
  exportCSVFail,
  getFrontUserCSVListSuccess,
  getRePaymentDistributionListSuccess,
  getDistributionViewCSVListSuccess,
  getRePaymentReceivedCSVListSuccess,
  getSavedInvestmentCSVListSuccess,
  getTransactionsCSVListSuccess,
} from "../../actions";
import {
  FRONT_END_USER,
  DISTRIBUTION_API,
  REPAYMENT_API,
  SAVED_INVESTMENT_LIST,
  TRANSACTIONS_EXPORT_API,
} from "../../actions/apiCollections";

let PageLimit = 5000;

export function* getFrontUserCSVList(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(FRONT_END_USER, {
      params: { limit: 10 * PageLimit },
    });
    yield put(getFrontUserCSVListSuccess(response.data.data.data.docs));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getRePaymentDistributionCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${DISTRIBUTION_API}${action.id}`);
    yield put(
      getRePaymentDistributionListSuccess(
        response.data.data.data?.distributionData
      )
    );
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getDistributionViewCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${DISTRIBUTION_API}${action.id}`);
    yield put(
      getDistributionViewCSVListSuccess(
        response.data.data.data?.distributionData
      )
    );
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getRePaymentReceivedCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${REPAYMENT_API}${action.id}`);
    yield put(
      getRePaymentReceivedCSVListSuccess(response.data.data.data?.repaymentData)
    );
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getSavedInvestmentCSVListSaga() {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(SAVED_INVESTMENT_LIST, {
      params: { limit: 10 * PageLimit },
    });
    yield put(getSavedInvestmentCSVListSuccess(response));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getTransactionsCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${TRANSACTIONS_EXPORT_API}`, {
      params: {
        ...(action.response.campaign !== "" && {
          campaign: action.response.campaign,
        }),
        ...(action.response.investor !== "" && {
          user: action.response.investor,
        }),
        ...(action.response.minamount !== "" && {
          minAmount: action.response.minamount,
        }),
        ...(action.response.maxamount !== "" && {
          maxAmount: action.response.maxamount,
        }),
        ...(action.response.transaction !== "" && {
          transaction: action.response.transaction,
        }),
        ...(action.response.preapprovalStatus !== "" && {
          preapprovalStatus: action.response.preapprovalStatus,
        }),
      },
    });
    yield put(getTransactionsCSVListSuccess(response.data.data.data));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}
