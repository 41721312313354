import * as actionTypes from "./actionTypes";

/* list actions */
export const getAccountVerificationUsersStart = () => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFICATION_USERS_START,
  };
};

export const getAccountVerificationUsers = (search, pageLimit) => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFICATION_USERS,
    search,
    pageLimit,
  };
};

export const getAccountVerificationUsersSuccess = (response) => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFICATION_USERS_SUCCESS,
    response,
  };
};

export const getAccountVerificationUsersFail = (error) => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFICATION_USERS_FAIL,
    error,
  };
};

/* get single account verification user actions */
export const getAccountVerificationUserStart = () => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFICATION_USER_START,
  };
};

export const getAccountVerificationUser = (id) => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFICATION_USER,
    id,
  };
};

export const getAccountVerificationUserSuccess = (response) => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFICATION_USER_SUCCESS,
    response,
  };
};

export const getAccountVerificationUserFail = (error) => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFICATION_USER_FAIL,
    error,
  };
};

/* update account verification data actions */

export const editAccountVerificationUserStart = () => {
  return {
    type: actionTypes.EDIT_ACCOUNT_VERIFICATION_USER_START,
  };
};

export const editAccountVerificationUser = (id, formData) => {
  return {
    type: actionTypes.EDIT_ACCOUNT_VERIFICATION_USER,
    formData,
    id,
  };
};

export const editAccountVerificationUserSuccess = (response) => {
  return {
    type: actionTypes.EDIT_ACCOUNT_VERIFICATION_USER_SUCCESS,
    response,
  };
};

export const editAccountVerificationUserFail = (error) => {
  return {
    type: actionTypes.EDIT_ACCOUNT_VERIFICATION_USER_FAIL,
    error,
  };
};
