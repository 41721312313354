import * as actionTypes from "../../actions/notificationTemplates/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: [],
  notifications: [],
  notificationTemplates: [],
  notificationTemplate: [],
  isUpdated: false,
};

export const notificationTemplatesStart = (state, action) => {
  return updateObject(state, {
    error: [],
    notifications: [],
    isUpdated: false,
  });
};

export const getNotificationTemplates = (state, action) => {
  return updateObject(state, {
    notificationTemplates: action.response,
  });
};

export const getNotificationTemplate = (state, action) => {
  return updateObject(state, {
    notificationTemplate: action.response,
  });
};

const isUpdated = (state, action) => {
  return updateObject(state, {
    isUpdated: true,
  });
};

const getNotifications = (state, action) => {
  return updateObject(state, {
    notifications: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_TEMPLATES_START:
      return notificationTemplatesStart(state, action);
    case actionTypes.GET_NOTIFICATION_TEMPLATES_SUCCESS:
      return getNotificationTemplates(state, action);
    case actionTypes.GET_NOTIFICATION_TEMPLATE_SUCCESS:
      return getNotificationTemplate(state, action);
    case actionTypes.UPDATE_NOTIFICATION_TEMPLATE_SUCCESS:
      return isUpdated(state, action);
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return getNotifications(state, action);
    default:
      return state;
  }
};

export default reducer;
