import React, { useState, useEffect } from "react";
import {
  MixedWidget1,
  ListsWidget9,
  StatsWidget1,
  StatsWidget11,
  StatsWidget12,
  AdvanceTablesWidget2,
} from "../widgets";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import {
  setDashboardSelectedCurrency,
  getDashboardCampaignsRevenues,
  getDashboardCampaignTotalInvestment,
  getDashboardCampaignTotalRepayment,
} from "../../../store/actions/dashboard/dashboardActions";
import * as actions from "../../../store/actions/index";

export function Demo1Dashboard() {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const taxonomy = useSelector((state) => state?.taxonomy?.taxonomy);
  const { activeCurrency } = useSelector((state) => state.misc);
  const { dashboardSelectedCurrency } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(actions.getMeta());
    dispatch(actions.getGeneralSettings());
  }, [dispatch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 5500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isVisible]);

  const changeCurrencyHandler = (currencyId) => {
    dispatch(setDashboardSelectedCurrency(currencyId));
    dispatch(getDashboardCampaignsRevenues(currencyId));
    dispatch(getDashboardCampaignTotalInvestment(currencyId));
    dispatch(getDashboardCampaignTotalRepayment(currencyId));
    setIsVisible(true);
  };

  return (
    <>
      <div className="col-xxl-12 pl-0">
        {activeCurrency?.doc?.map((currency, index) => (
          <Button
            disabled={isVisible}
            variant={
              dashboardSelectedCurrency === currency?._id
                ? "primary"
                : "secondary"
            }
            className="rounded-pill ml-3"
            key={index}
            style={{
              fontWeight: "bold",
              padding: "10px 31px",
            }}
            onClick={() => changeCurrencyHandler(currency?._id)}
          >
            {currency?.code}
          </Button>
        ))}
      </div>

      <div className="col-xxl-12 order-2 order-xxl-1 mt-10">
        <StatsWidget1 className="gutter-b" taxonomy={taxonomy} />
      </div>

      <div className="row">
        <div className="col-lg-6 col-xxl-4">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>

        <div className="col-lg-6 col-xxl-4">
          <StatsWidget11 className="card-stretch gutter-b" />
        </div>

        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
          <StatsWidget12 className="card-stretch gutter-b" />
        </div>

        <div className="col-xxl-12 order-2 order-xxl-1">
          <AdvanceTablesWidget2
            className="card-stretch gutter-b"
            taxonomy={taxonomy}
          />
        </div>

        <div className="col-xxl-12 order-2 order-xxl-1">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
