import * as actionTypes from "./actionTypes";

export const taxonomyStart = () => {
  return {
    type: actionTypes.TAXONOMY_START,
  };
};

export const taxonomyFail = (error) => {
  return {
    type: actionTypes.TAXONOMY_FAIL,
    error,
  };
};

export const getTaxomony = () => {
  return {
    type: actionTypes.GET_TAXONOMY,
  };
};

export const getTaxomonySuccess = (response) => {
  return {
    type: actionTypes.GET_TAXONOMY_SUCCESS,
    response,
  };
};

export const createTaxomony = (taxonomy) => {
  return {
    type: actionTypes.CREATE_TAXONOMY,
    taxonomy,
  };
};

export const createTaxomonySuccess = (response) => {
  return {
    type: actionTypes.CREATE_TAXONOMY_SUCCESS,
    response,
  };
};

export const updateTaxomony = (taxonomy) => {
  return {
    type: actionTypes.UPDATE_TAXONOMY,
    taxonomy,
  };
};

export const updateTaxomonySuccess = (response) => {
  return {
    type: actionTypes.UPDATE_TAXONOMY_SUCCESS,
    response,
  };
};
