import { put, call } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
  settingsStart,
  getGeneralSettingsSuccess,
  createGeneralSettingsSuccess,
  updateGeneralSettingsSuccess,
  getCampaignSettingsSuccess,
  settingsFail,
  createCampaignSettingsSuccess,
  updateCampaignSettingsSuccess,
  getAllUsersContactEnquirySuccess,
  getNewsletterSettingsSuccess,
  updateNewsletterSettingsSuccess,
  setDashboardSelectedCurrency,
  getDatabaseBackupSuccess,
  downloadDatabaseBackupSuccess,
} from "../actions/index";
import {
  SITE_SETTINGS_API,
  CAMPAIGN_SETTING_API,
  USERS_CONTACT_LIST_API,
  NEWSLETTER_LIST_API,
  DATABASE_BACKUP_API,
  DOWNLOAD_DATABASE_BACKUP_API,
} from "../actions/apiCollections";

import { getAllActiveCurrenciesSaga } from "../sagas/miscSaga";

export function* getFrontSiteSettingsSaga() {
  yield put(settingsStart());
  try {
    const response = yield axios.get(`${SITE_SETTINGS_API}/front`, {
      params: { status: true },
    });
    yield put(getGeneralSettingsSuccess(response.data.data.data));
  } catch (error) {
    yield put(settingsFail(error.response));
  }
}

export function* getSiteSettingsSaga() {
  yield put(settingsStart());
  try {
    const response = yield axios.get(SITE_SETTINGS_API, {
      params: { status: true },
    });
    yield put(getGeneralSettingsSuccess(response.data.data.data));
    yield put(
      setDashboardSelectedCurrency(response.data.data.data.currencyId.id)
    );
    yield call(getAllActiveCurrenciesSaga);
  } catch (error) {
    yield put(settingsFail(error.response));
  }
}

export function* createSiteSettingsSaga(action) {
  try {
    const response = yield axios.post(
      SITE_SETTINGS_API,
      action.generalSettings
    );
    yield put(createGeneralSettingsSuccess(response));
  } catch (error) {
    yield put(settingsFail(error.response));
  }
}

export function* updateSiteSettingsSaga(action) {
  let id = new FormData();
  id = action.generalSettings;
  id = id.get("id");
  try {
    const response = yield axios.patch(
      `${SITE_SETTINGS_API}/${id}`,
      action.generalSettings
    );
    yield put(updateGeneralSettingsSuccess(response));
    yield call(getSiteSettingsSaga);
  } catch (error) {
    yield put(settingsFail(error.response));
  }
}

export function* getCampaignSettings() {
  yield put(settingsStart());
  try {
    const response = yield axios.get(`${CAMPAIGN_SETTING_API}?lng=en`);
    yield put(getCampaignSettingsSuccess(response.data.data.data));
  } catch (error) {
    yield put(settingsFail(error.response.data));
  }
}

export function* createCampaignSettingsSaga(action) {
  try {
    const response = yield axios.post(
      `${CAMPAIGN_SETTING_API}?lng=en`,
      action.campaign
    );
    yield put(createCampaignSettingsSuccess(response.data.data.data));
    yield call(getCampaignSettings);
  } catch (error) {
    yield put(settingsFail(error.response.data));
  }
}

export function* updateCampaignSettingsSaga(action) {
  let id = new FormData();
  id = action.campaign;
  id = id.get("id");
  try {
    const response = yield axios.patch(
      `${CAMPAIGN_SETTING_API}${id}?lng=en`,
      action.campaign
    );
    yield put(updateCampaignSettingsSuccess(response.data.data.data));
    yield call(getCampaignSettings);
  } catch (error) {
    yield put(settingsFail(error.response.data));
  }
}

export function* getAllUsersContactEnquirySaga(action) {
  yield put(settingsStart());
  try {
    const response = yield axios.get(`${USERS_CONTACT_LIST_API}`, {
      params: { limit: 10 * action.response },
    });
    yield put(getAllUsersContactEnquirySuccess(response.data.data.data));
  } catch (error) {
    yield put(settingsFail(error.response.data));
  }
}

export function* getNewsletterSettingsSaga() {
  try {
    const response = yield axios.get(`${NEWSLETTER_LIST_API}`);
    yield put(getNewsletterSettingsSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
    // yield put(authRegistrationFail(error.response.data.errors[0].msg));
  }
}

export function* updateNewsletterSettingsSaga(action) {
  try {
    const response = yield axios.patch(
      `${NEWSLETTER_LIST_API}${action.id}`,
      action.newsletter
    );
    yield put(updateNewsletterSettingsSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getDatabaseBackupSaga() {
  try {
    const response = yield axios.get(`${DATABASE_BACKUP_API}`);
    yield put(getDatabaseBackupSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* downloadDatabaseBackupSaga(action) {
  try {
    const response = yield axios.post(
      `${DOWNLOAD_DATABASE_BACKUP_API}/${action.dbdetails.databaseId}?lng=en`,
      action.dbdetails
    );
    yield put(downloadDatabaseBackupSuccess(response.data.data.data));
  } catch (error) {
    yield put(settingsFail(error.response.data));
  }
}
