export const SECONDARY_MARKET_START = "SECONDARY_MARKET_START";
export const SECONDARY_MARKET_FAIL = "SECONDARY_MARKET_FAIL";
export const GET_SECONDARY_MARKET_SETTING = "GET_SECONDARY_MARKET_SETTING";
export const GET_SECONDARY_MARKET_SETTING_SUCCESS =
  "GET_SECONDARY_MARKET_SETTING_SUCCESS";

export const UPDATE_SECONDARY_MARKET_SETTING =
  "UPDATE_SECONDARY_MARKET_SETTING";
export const UPDATE_SECONDARY_MARKET_SETTING_SUCCESS =
  "UPDATE_SECONDARY_MARKET_SETTING_SUCCESS";

export const GET_SECONDARY_MARKET_SELLER_TRADE =
  "GET_SECONDARY_MARKET_SELLER_TRADE";
export const GET_SECONDARY_MARKET_SELLER_TRADE_SUCCESS =
  "GET_SECONDARY_MARKET_SELLER_TRADE_SUCCESS";

export const GET_SECONDARY_MARKET_BUYER_TRADE =
  "GET_SECONDARY_MARKET_BUYER_TRADE";
export const GET_SECONDARY_MARKET_BUYER_TRADE_SUCCESS =
  "GET_SECONDARY_MARKET_BUYER_TRADE_SUCCESS";

export const GET_SELLER_TRADE = "GET_SELLER_TRADE";
export const GET_SELLER_TRADE_SUCCESS = "GET_SELLER_TRADE_SUCCESS";

export const GET_SELLER_CALCULATE_FEES = "GET_SELLER_CALCULATE_FEES";

export const UPDATE_SECONDARY_MARKET_SELLER_TRADE =
  "UPDATE_SECONDARY_MARKET_SELLER_TRADE";
export const UPDATE_SECONDARY_MARKET_SELLER_TRADE_SUCCESS =
  "UPDATE_SECONDARY_MARKET_SELLER_TRADE_SUCCESS";
export const APPROVE_SECONDARY_MARKET_SELLER_TRADE_PAYOUT_REQUEST =
  "APPROVE_SECONDARY_MARKET_SELLER_TRADE_PAYOUT_REQUEST";
export const UPDATE_SECONDARY_MARKET_BUYER_CONTRACT_DOCUMENT_REQUEST =
  "UPDATE_SECONDARY_MARKET_BUYER_CONTRACT_DOCUMENT_REQUEST";
