import * as actionTypes from "../../actions/secondaryMarket/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: null,
  secondaryMarketSetting: [],
  secondaryMarketSellers: [],
  secondaryMarketBuyers: [],
  sellerRequestDetail: null,
  sellerCalculateFees: null,
  successAlert: true,
  isSpinning: false,
};

export const secondaryMarketStart = (state, action) => {
  return updateObject(state, {
    isSpinning: true,
    error: null,
    successAlert: false,
    sellerRequestDetail: null,
    sellerCalculateFees: null,
  });
};

export const secondaryMarketFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    successAlert: false,
  });
};

export const getSecondaryMarketSetting = (state, action) => {
  return updateObject(state, {
    secondaryMarketSetting: action.response,
    successAlert: false,
  });
};

export const getSecondaryMarketSellerTrades = (state, action) => {
  return updateObject(state, {
    secondaryMarketSellers: action.response,
    successAlert: false,
  });
};

export const getSecondaryMarketBuyerTradesSuccess = (state, action) => {
  return updateObject(state, {
    secondaryMarketBuyers: action.response,
    successAlert: false,
  });
};

export const getSellerTradeSuccess = (state, action) => {
  return updateObject(state, {
    sellerRequestDetail: action.response,
  });
};

export const getSellerCalculateFees = (state, action) => {
  return updateObject(state, {
    sellerCalculateFees: action.response,
  });
};

export const updateSecondaryMarketSellerSuccess = (state, action) => {
  return updateObject(state, {
    sellerRequestDetail: action.response,
    successAlert: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SECONDARY_MARKET_START:
      return secondaryMarketStart(state, action);
    case actionTypes.SECONDARY_MARKET_FAIL:
      return secondaryMarketFail(state, action);
    case actionTypes.GET_SECONDARY_MARKET_SETTING_SUCCESS:
      return getSecondaryMarketSetting(state, action);
    case actionTypes.GET_SECONDARY_MARKET_SELLER_TRADE_SUCCESS:
      return getSecondaryMarketSellerTrades(state, action);
    case actionTypes.GET_SECONDARY_MARKET_BUYER_TRADE_SUCCESS:
      return getSecondaryMarketBuyerTradesSuccess(state, action);
    case actionTypes.GET_SELLER_TRADE_SUCCESS:
      return getSellerTradeSuccess(state, action);
    case actionTypes.GET_SELLER_CALCULATE_FEES:
      return getSellerCalculateFees(state, action);
    case actionTypes.UPDATE_SECONDARY_MARKET_SELLER_TRADE_SUCCESS:
      return updateSecondaryMarketSellerSuccess(state, action);
    default:
      return state;
  }
};
export default reducer;
