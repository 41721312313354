import { put } from "redux-saga/effects";
import { USER_INVESTMENTS_LIST } from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";
import * as actions from "../../actions/";

export function* getUserInvestments(action) {
  yield put(actions.userInvestmentStart());
  try {
    const response = yield axios.get(
      `${USER_INVESTMENTS_LIST}/${action.response.user}`,
      {
        params: { limit: 10 * action.response.limit },
      }
    );
    yield put(actions.getUserInvestmentSuccess(response?.data?.data?.data));
  } catch (error) {
    console.log("printing error");
    console.log(error);
    yield put(actions.userInvestmentFail(error));
  }
}
