import * as actionTypes from "./actionTypes";

export const notificationTemplatesStart = () => {
  return {
    type: actionTypes.NOTIFICATION_TEMPLATES_START,
  };
};

export const getNotificationTemplates = (language, pageLimit) => {
  return {
    type: actionTypes.GET_NOTIFICATION_TEMPLATES,
    language,
    pageLimit,
  };
};

export const getNotificationTemplatesSuccess = (response) => {
  return {
    type: actionTypes.GET_NOTIFICATION_TEMPLATES_SUCCESS,
    response,
  };
};

export const getNotificationTemplate = (id, language) => {
  return {
    type: actionTypes.GET_NOTIFICATION_TEMPLATE,
    id,
    language,
  };
};

export const getNotificationTemplateSuccess = (response) => {
  return {
    type: actionTypes.GET_NOTIFICATION_TEMPLATE_SUCCESS,
    response,
  };
};

export const updateNotificationTemplate = (notificationData) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_TEMPLATE,
    notificationData,
  };
};

export const updateNotificationTemplateSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_TEMPLATE_SUCCESS,
    response,
  };
};

export const getNotifications = (limit) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS,
    limit,
  };
};

export const getNotificationsSuccess = (response) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    response,
  };
};

export const getReadNotifications = () => {
  return {
    type: actionTypes.POST_READ_NOTIFICATIONS,
  };
};
