import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  loading: false,
  errors: null,
  currencies: [],
  error: false,
  currencyInserted: false,
  currency: [],
  activeCurrency: [],
  currencyUpdated: false,
  languages: [],
  languageCreated: false,
  language: [],
  languageUpdated: false,
  sliders: [],
  sliderCreated: false,
  slider: [],
  sliderUpdated: false,
  menuItems: [],
  contentPage: null,
  menuItem: [],
  successStories: [],
};

const miscStart = (state, action) => {
  return updateObject(state, {
    error: false,
    loading: false,
    currency: [],
    errors: null,
    languageCreated: false,
    language: [],
    languageUpdated: false,
    sliderCreated: false,
    sliderUpdated: false,
    slider: [],
    menuItem: [],
    menuItems: [],
    successStories: [],
    activeCurrency: [],
  });
};
const miscFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errors: action.error,
  });
};

const getCurrencies = (state, action) => {
  return updateObject(state, {
    currencies: action.response,
    currencyUpdated: false,
    currency: [],
    currencyInserted: false,
  });
};

const getAllActiveCurrencies = (state, action) => {
  return updateObject(state, {
    activeCurrency: action.response,
  });
};

const currencyInserted = (state, action) => {
  return updateObject(state, {
    currencyInserted: true,
  });
};
const getCurrency = (state, action) => {
  return updateObject(state, {
    currency: action.response,
  });
};
const currencyUpdate = (state, action) => {
  return updateObject(state, {
    currencyUpdated: true,
  });
};

const getLanguages = (state, action) => {
  return updateObject(state, {
    errors: null,
    error: false,
    languages: action.response,
    languageUpdated: false,
    languageCreated: false,
  });
};

const createLanguage = (state, action) => {
  return updateObject(state, {
    errors: null,
    error: false,
    languageCreated: action.response,
  });
};

const getLanguage = (state, action) => {
  return updateObject(state, {
    language: action.response,
  });
};
const updateLanguage = (state, action) => {
  return updateObject(state, {
    languageUpdated: true,
  });
};

const getSliders = (state, action) => {
  return updateObject(state, {
    sliders: action.response,
    loading: false,
    slider: [],
  });
};

const createdSlider = (state, action) => {
  return updateObject(state, {
    sliderCreated: true,
  });
};

const getSlider = (state, action) => {
  return updateObject(state, {
    slider: action.response,
  });
};
const sliderUpdated = (state, action) => {
  return updateObject(state, {
    sliderUpdated: true,
  });
};

const menuItems = (state, action) => {
  return updateObject(state, {
    menuItems: action.response,
  });
};

const getContentPageId = (state, action) => {
  return updateObject(state, {
    contentPage: action.response,
  });
};

const getMenuItem = (state, action) => {
  return updateObject(state, {
    menuItem: action.response,
  });
};

const getSuccessStories = (state, action) => {
  return updateObject(state, {
    successStories: action.response,
  });
};

const createSuccessStories = (state, action) => {
  return updateObject(state, {
    successStoriesCreated: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MISC_START:
      return miscStart(state, action);
    case actionTypes.MISC_FAIL:
      return miscFail(state, action);
    case actionTypes.GET_CURRENCIES_SUCCESS:
      return getCurrencies(state, action);
    case actionTypes.CREATE_CURRENCY_SUCCESS:
      return currencyInserted(state, action);
    case actionTypes.GET_CURRENCY_SUCCESS:
      return getCurrency(state, action);
    case actionTypes.GET_ALL_FRONT_CURRENCIES_SUCCESS:
      return getAllActiveCurrencies(state, action);
    case actionTypes.UPDATE_CURRENCY_SUCCESS:
    case actionTypes.BULK_UPDATE_CURRENCY_SUCCESS:
      return currencyUpdate(state, action);
    case actionTypes.GET_MISC_LANGUAGES_SUCCESS:
      return getLanguages(state, action);
    case actionTypes.CREATE_MISC_LANGUAGE_SUCCESS:
      return createLanguage(state, action);
    case actionTypes.GET_MISC_LANGUAGE_SUCCESS:
      return getLanguage(state, action);
    case actionTypes.UPDATE_MISC_LANGUAGE_SUCCESS:
      return updateLanguage(state, action);
    case actionTypes.GET_MISC_SLIDERS_SUCCESS:
      return getSliders(state, action);
    case actionTypes.CREATE_MISC_SLIDER_SUCCESS:
      return createdSlider(state, action);
    case actionTypes.GET_MISC_SLIDER_SUCCESS:
      return getSlider(state, action);
    case actionTypes.MISC_SLIDER_UPDATE_SUCCESS:
      return sliderUpdated(state, action);
    case actionTypes.GET_MENU_SUCCESS:
      return menuItems(state, action);
    case actionTypes.GET_CONTENT_PAGE_SUCCESS:
      return getContentPageId(state, action);
    case actionTypes.GET_MENU_ITEM_SUCCESS:
      return getMenuItem(state, action);
    case actionTypes.GET_SUCCESS_STORIES_SUCCESS:
      return getSuccessStories(state, action);
    case actionTypes.CREATE_SUCCESS_STORIES_SUCCESS:
      return createSuccessStories(state, action);
    default:
      return state;
  }
};

export default reducer;
