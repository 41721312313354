/* eslint-disable no-unused-vars */
import { call, put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
  FRONT_END_USER,
  BULK_FRONT_END_USER,
  USER_TYPE_API,
} from "../actions/apiCollections";
import {
  frontEndUsersStart,
  frontEndUsersFail,
  getFrontEndUsersSuccess,
  createFrontEndUserSuccess,
  getFrontEndUserSuccess,
  updateFrontEndUserSuccess,
  batchFrontEndUpdateSuccess,
  userTypesSuccess,
} from "../actions/index";
import * as mangopayActions from "../actions/mangopay/mangopayActions";

export function* getFrontEndUsersSaga(action) {
  yield put(frontEndUsersStart());
  yield put(mangopayActions.mangopayWalletStart());
  try {
    const response = yield axios.get(FRONT_END_USER, {
      params: {
        ...(action.search !== "" && {
          search: action.search,
        }),

        limit: 10 * action.pageLimit,
      },
    });
    yield put(getFrontEndUsersSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* deleteFrontEndUserSaga(action) {
  try {
    yield axios.delete(`${FRONT_END_USER}${action.id}`);
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(getFrontEndUsersSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* createFrontEndUserSaga(action) {
  try {
    const response = yield axios.post(
      `${FRONT_END_USER}?lng=en`,
      action.frontEndUser
    );
    yield put(createFrontEndUserSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response.data.errors));
  }
}

export function* getFrontEndUserSaga(action) {
  try {
    const response = yield axios.get(`${FRONT_END_USER}${action.id}`);
    yield put(getFrontEndUserSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* updateFrontEndUserSaga(action) {
  let id = new FormData();
  id = action.frontEndUser;
  id = id.get("id");
  try {
    const response = yield axios.patch(
      `${FRONT_END_USER}${id}?lng=en`,
      action.frontEndUser
    );
    yield put(updateFrontEndUserSuccess(response));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* batchUpdateFrontEndUserSaga(action) {
  try {
    const response = yield axios.post(
      `${BULK_FRONT_END_USER}`,
      action.frontEndData
    );
    yield put(frontEndUsersStart());
    yield put(batchFrontEndUpdateSuccess(response.data.data.data));
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(getFrontEndUsersSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* batchDeleteFrontEndUserSaga(action) {
  yield put(frontEndUsersStart());
  try {
    yield axios.delete(`${BULK_FRONT_END_USER}`, action.frontEndData);
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(getFrontEndUsersSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* userTypeSaga(filterData) {
  try {
    const response = yield axios.get(USER_TYPE_API);
    yield put(userTypesSuccess(response.data.data.data.docs));
  } catch (error) {
    console.log(error);
    // yield put(authRegistrationFail(error.response.data.errors[0].msg));
  }
}
