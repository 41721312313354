import * as actionTypes from "./actionTypes";

export const dashboardStart = () => {
  return {
    type: actionTypes.DASHBOARD_START,
  };
};

export const dashboardFail = (error) => {
  return {
    type: actionTypes.DASHBOARD_FAIL,
    error,
  };
};

export const getDashboardCount = () => {
  return {
    type: actionTypes.GET_DASHBOARD_COUNT,
  };
};

export const getDashboardCountSuccess = (response) => {
  return {
    type: actionTypes.GET_DASHBOARD_COUNT_SUCCESS,
    response,
  };
};

export const getDashboardPendingCampaigns = (id) => {
  return {
    type: actionTypes.GET_DASHBOARD_PENDING_CAMPAIGNS,
    id,
  };
};

export const getDashboardPendingCampaignsSuccess = (response) => {
  return {
    type: actionTypes.GET_DASHBOARD_PENDING_CAMPAIGNS_SUCCESS,
    response,
  };
};

export const getDashboardCampaignsRevenues = (currencyId) => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGNS_REVENUES,
    currencyId
  };
};

export const getDashboardCampaignsRevenuesSuccess = (response) => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGNS_REVENUES_SUCCESS,
    response,
  };
};

export const getDashboardCampaignTotalInvestment = (currencyId) => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGN_TOTAL_INVESTMENT,
    currencyId
  };
};

export const getDashboardCampaignTotalInvestmentSuccess = (response) => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGN_TOTAL_INVESTMENT_SUCCESS,
    response,
  };
};

export const getDashboardCampaignTotalRepayment = (currencyId) => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGN_TOTAL_REPAYMENT,
    currencyId
  };
};

export const getDashboardCampaignTotalRepaymentSuccess = (response) => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGN_TOTAL_REPAYMENT_SUCCESS,
    response,
  };
};

export const setDashboardSelectedCurrency = currency => {
	return {
		type: actionTypes.SET_DASHBOARD_SELECTED_CURRENCY,
		currency
	};
};
