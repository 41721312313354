/* eslint-disable no-unused-vars */
import React, { useMemo, useEffect, useContext, useRef } from "react";
import objectPath from "object-path";
import { useDispatch, useSelector } from "react-redux";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { QuickPanel } from "./extras/offcanvas/QuickPanel";
import { QuickUser } from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import { StickyToolbar } from "./extras/StickyToolbar";
import { ViewUser } from "../../../app/pages/UserManagement/FrontEndUsers/ViewUser.js";
import { getBaseImageURL } from "../../../helpers";
import * as actions from "../../../store/actions/index";

export function Layout({ children }) {
  const dispatch = useDispatch();
  // const socket = useContext(SocketContext);
  const { currentAdmin } = useSelector((state) => state.adminAuth);
  const { generalSettings } = useSelector((state) => state.settings);
  const { meta } = useSelector((state) => state.meta);

  const globalImagePath = useRef(getBaseImageURL());

  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended"),
    };
  }, [uiService]);

  /**
   * Adds an event listener for the visibilitychange event when the component mounts.
   * When the page becomes visible again, it calls showNotification to request notification permission.
   * Removes the event listener when the component unmounts.
   */
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        showNotification();
      }
    };

    const showNotification = () => {
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
          }
        });
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  /**
   * Use socket.io to emit the current user ID to all connected sockets
   * when the current user changes. This allows other connected clients to update
   * based on the current user.
   */
  // useEffect(() => {
  //   if (currentAdmin?.id) {
  //     const adminData = {
  //       userId: currentAdmin?.id,
  //       userType: "admin",
  //     };
  //     socket?.emit("globalSockets", adminData);
  //   }
  // }, [currentAdmin, socket]);

  /**
   * Set up a socket.io listener to show browser notifications
   * when a new notification is received from the server.
   *
   * Check if notifications are allowed, and request permission if needed.
   * Show the notification if permissions are granted.
   */
  // useEffect(() => {
  //   socket.on(
  //     `${process.env.REACT_APP_SOCKET_MAIN_ID}`,
  //     async (notification) => {
  //       if (Notification.permission === "granted") {
  //         showNotification(notification);
  //         dispatch(actions.getNotifications(1));
  //       } else {
  //         const permission = await Notification.requestPermission();
  //         if (permission === "granted") {
  //           showNotification(notification);
  //         }
  //       }
  //     }
  //   );
  // }, []);

  /**
   * Shows a browser notification using the Notification API.
   *
   * @param {Object} notification - The notification object containing the notification message.
   */
  // const showNotification = (notification) => {
  //   new Notification(generalSettings?.siteName, {
  //     body: notification?.text,
  //     icon: `${globalImagePath?.current}${generalSettings?.favIcon}`,
  //     image: `${globalImagePath?.current}${notification?.thumbnail}`,
  //   });
  // };

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {layoutProps.subheaderDisplay && <SubHeader />}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>
                    {children}
                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      {/* <ViewUser/> */}
      <QuickUser />
      <QuickPanel />
      <ScrollTop />
      {/* <StickyToolbar /> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
