import * as actionTypes from "../../actions/dashboard/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: null,
  dashboardCount: [],
  isLoading: true,
  pendingCampaign: [],
  totalCampaignRevenues: [],
  totalCampaignInvestments: [],
  totalCampaignRePayment: [],
  dashboardSelectedCurrency: ""
};
const dashboardStart = (state, action) => {
  return updateObject(state, {
    error: null,
    dashboardCount: [],
    totalCampaignRevenues: [],
    totalCampaignInvestments: [],
    totalCampaignRePayment: [],
    isLoading: true,
    dashboardSelectedCurrency: ""
  });
};

const setDashboardSelectedCurrency = (state, action) => {
	return updateObject(state, {
		dashboardSelectedCurrency: action.currency
	});
};

const dashboardFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const getDashboardCount = (state, action) => {
  return updateObject(state, {
    dashboardCount: action.response,
  });
};

const getDashboardPendingCampaigns = (state, action) => {
  return updateObject(state, {
    pendingCampaign: action.response,
  });
};

const getDashboardCampaignsRevenuesSuccess = (state, action) => {
  return updateObject(state, {
    totalCampaignRevenues: action.response,
  });
};

const getDashboardCampaignTotalInvestmentSuccess = (state, action) => {
  return updateObject(state, {
    totalCampaignInvestments: action.response,
  });
};

const getDashboardCampaignTotalRepaymentSuccess = (state, action) => {
  return updateObject(state, {
    totalCampaignRePayment: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_START:
      return dashboardStart(state, action);
    case actionTypes.DASHBOARD_FAIL:
      return dashboardFail(state, action);
    case actionTypes.GET_DASHBOARD_COUNT_SUCCESS:
      return getDashboardCount(state, action);
    case actionTypes.GET_DASHBOARD_PENDING_CAMPAIGNS_SUCCESS:
      return getDashboardPendingCampaigns(state, action);
    case actionTypes.GET_DASHBOARD_CAMPAIGNS_REVENUES_SUCCESS:
      return getDashboardCampaignsRevenuesSuccess(state, action);
    case actionTypes.GET_DASHBOARD_CAMPAIGN_TOTAL_INVESTMENT_SUCCESS:
      return getDashboardCampaignTotalInvestmentSuccess(state, action);
    case actionTypes.GET_DASHBOARD_CAMPAIGN_TOTAL_REPAYMENT_SUCCESS:
      return getDashboardCampaignTotalRepaymentSuccess(state, action);
    case actionTypes.SET_DASHBOARD_SELECTED_CURRENCY:
			return setDashboardSelectedCurrency(state, action);
    default:
      return state;
  }
};

export default reducer;
