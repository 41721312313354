import * as actionTypes from "./actionTypes";

export const savedInvestmentStart = () => {
  return {
    type: actionTypes.SAVED_INVESTMENT_START,
  };
};

export const getSavedInvestments = (response) => {
  return {
    type: actionTypes.GET_SAVED_INVESTMENT,
    response,
  };
};

export const getSavedInvestmentSuccess = (response) => {
  return {
    type: actionTypes.GET_SAVED_INVESTMENTS_SUCCESS,
    response,
  };
};

export const savedInvestmentFail = (error) => {
  return {
    type: actionTypes.GET_SAVED_INVESTMENTS_FAIL,
    error,
  };
};

export const deleteSavedInvestment = (action) => {
  return {
    type: actionTypes.DELETE_SAVED_INVESTMENTS,
    id:action.id,
    user:action.user
  };
};

export const getSearchSavedInvestments = (response, limitNo) => {
  return {
    type: actionTypes.GET_SEARCHED_SAVED_INVESTMENTS,
    response,
    limitNo,
  };
};
